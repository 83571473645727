export const cardInfo = [
  {
    id: 1,
    text: "Агенция за украса “Вълшебно цвете” Ви предлага покани за Вашите гости. Може да изберете от готовите ни модели или да направим индивидуален модел в зависимост от темата и цветовете на определения повод.",
  },
  {
    id: 2,
    text: "За ваше удобство и на вашите гости , ние предлагаме също изработване на картички с имена на гости за разпределение по маси. Те ще бъдат изработени в цвета и темата на вашата украса.",
  },
  {
    id: 3,
    text: "За добрата организация на събитието може да поръчате и разпределителна табела с имената на гостите. Тя също може да бъде изработена като бъде съобразена с тематиката на събитието. Това ще бъде вашето табло за настаняване на гостите в ресторанта.",
  },
  {
    id: 4,
    text: "Поканите за гости , картичките с имена за масите и настанителните табла могат да бъдат изработени по индивидуален проект. Разбира се , може да се възползвате и от нашите готови предложения:",
  },
];
