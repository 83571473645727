import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";
import * as authService from "../../services/authService";

const Logout = () => {
    const navigate = useNavigate();
    const { user, userLogout } = useContext(AuthContext);
    useEffect(() => {
      authService
        .logout()
        .then(() => {
          userLogout();
          navigate("/");
        })
        .catch((err) => {
          console.log(err);
          navigate("/error");
        });
    });
  
    return null;
  };
  
  export default Logout;